//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import BillingMixin from "@/components/Billing/Billing.mixin";
export default {
  mixins: [BillingMixin],
  components: {
    DataTable
  },
  props: {},
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("billing/getHistory", {
        ...query
      });
    }
  },
  computed: {
    columns() {
      return [{
        title: "Дата",
        show: true,
        name: "date_paid",
        width: 200,
        filter: "dateRange",
        type: "datetime"
      }, {
        title: "Сумма",
        show: true,
        name: "sum",
        type: "money",
        width: 200
      }, {
        title: "Метод оплаты",
        position: 3,
        show: true,
        name: "payment_method",
        getText: v => {
          var _this$getPaymentMetho;
          return (_this$getPaymentMetho = this.getPaymentMethods().find(i => i.value === v)) === null || _this$getPaymentMetho === void 0 ? void 0 : _this$getPaymentMetho.text;
        }
      }
      /* 
      {
      title: "Тип отчета",
      show: true,
      name: "type",
      width: 250,
      },
      {
      title: "Название",
      show: true,
      name: "name",
      width: 250,
      type: "link",
      getLink: (item) => {
      if (!item.url) {
      return {
      route: "/",
      text: item.name,
      target: "_blank",
      };
      }
      const route = this.$router.resolve(item.url).route;
      return {
      route: {
      ...route,
      query: {
        ...route.query,
        _state: item.info === "{}" ? undefined : item.info,
      },
      },
      text: item.name,
      target: "_blank",
      };
      },
      },
      {
      title: "Период от",
      show: true,
      name: "report_date",
      width: 100,
      filter: "dateRange",
      type: "date",
      },
      {
      title: "Период до",
      show: true,
      name: "report_date2",
      width: 100,
      filter: "dateRange",
      type: "date",
      }, */].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};